import { RegionCode } from '@kaa/api/idp';
import { ValueOf } from '@kaa/common/types';
import { i18nKeys } from '@kaa/i18n/idp/keys';

export const IDP_IFRAME_DATA = 'sw.idp.data';

export const REGION_COMPLAINT_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.complaint,
  [RegionCode.BRU]: i18nKeys.bl.general.url.complaint,
  [RegionCode.WAL]: i18nKeys.wl.general.url.complaint,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_CONDITIONS_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.termsAndConditions,
  [RegionCode.BRU]: i18nKeys.bl.general.url.termsAndConditions,
  [RegionCode.WAL]: i18nKeys.wl.general.url.termsAndConditions,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_COOKIES_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.cookies,
  [RegionCode.BRU]: i18nKeys.bl.general.url.cookies,
  [RegionCode.WAL]: i18nKeys.wl.general.url.cookies,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_NEWS_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.news,
  [RegionCode.BRU]: i18nKeys.bl.general.url.news,
  [RegionCode.WAL]: i18nKeys.wl.general.url.news,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_PRIVACY_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.privacyPolicy,
  [RegionCode.BRU]: i18nKeys.bl.general.url.privacyPolicy,
  [RegionCode.WAL]: i18nKeys.wl.general.url.privacyPolicy,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_QUESTION_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.question,
  [RegionCode.BRU]: i18nKeys.bl.general.url.question,
  [RegionCode.WAL]: i18nKeys.wl.general.url.question,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_CONTACT_A_PROVIDER_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.contactAProvider,
  [RegionCode.BRU]: i18nKeys.bl.general.url.contactAProvider,
  [RegionCode.WAL]: i18nKeys.wl.general.url.contactAProvider,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_CONTACT_US_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.contactUs,
  [RegionCode.BRU]: i18nKeys.bl.general.url.contactUs,
  [RegionCode.WAL]: i18nKeys.wl.general.url.contactUs,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const REGION_FAQ_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url.faq,
  [RegionCode.BRU]: i18nKeys.bl.general.url.faq,
  [RegionCode.WAL]: i18nKeys.wl.general.url.faq,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };

export const PASS_URL = ({
  [RegionCode.VLG]: i18nKeys.vl.general.url,
  [RegionCode.BRU]: i18nKeys.bl.general.url,
  [RegionCode.WAL]: i18nKeys.wl.general.url,
} as any) as { [key in ValueOf<typeof RegionCode>]: string };
