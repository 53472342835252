import {
  CustomerVoucherSet,
  RegionCode,
  SupportType,
  VouchersOrderItemCreate,
} from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwColumn,
  SwFormGrid,
  SwInputTextField,
  SwLink,
  SwRadioField,
} from '@kaa/ui-flanders/components';
import { Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { dataTest } from '../../../../../datatest/keys';
import { getAvailableVouchers } from '../../../ServicesVouchers.utils';
import { supportTypeTranslate } from '../OrderModal.constants';
import { getOrderItems } from '../OrderModal.utils';
import { OrderTable } from './OrderTable';
import { getConfig } from '../../../../../../common/config';
import { getPath } from '../../../../../../providers/routes';
import { Routes } from '../../../../../routes';
import { AvailableVouchersTile } from '../../AvailableVouchersTile';

type OrderStepOrderProps = {
  buyableVouchers: CustomerVoucherSet[];
  minQuantity: number;
  maxQuantity: number;
  orderItems: VouchersOrderItemCreate[];
  supportType: string;
  saveSupportType: boolean;
};

export const OrderStepOrder = ({
  buyableVouchers,
  orderItems,
  supportType,
  minQuantity,
  maxQuantity,
  saveSupportType = false,
}: OrderStepOrderProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const availableVouchers = getAvailableVouchers(buyableVouchers);

  const [supportTypeOptionsVisible, setSupportTypeOptionsVisible] = useState(
    saveSupportType,
  );

  useEffect(() => {
    if (!supportTypeOptionsVisible && supportType === SupportType.PAPER) {
      setSupportTypeOptionsVisible(true);
    }
  }, [supportType, supportTypeOptionsVisible]);

  const handleSupportTypeChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: FieldProps['field'],
    form: FieldProps['form'],
    minQuantity: number,
  ) => {
    const quantity = form.touched.quantity
      ? Math.max(form.values.quantity, minQuantity)
      : minQuantity;

    form.setFieldValue('quantity', quantity);
    form.setFieldValue(
      'orderItems',
      getOrderItems({
        buyableVouchers,
        quantity,
      }),
    );
  };

  const handleQuantityChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: FieldProps['field'],
    form: FieldProps['form'],
  ) => {
    form.setFieldValue(
      'orderItems',
      getOrderItems({
        buyableVouchers,
        quantity: parseInt(event.target.value, 10),
      }),
    );
  };

  const supportTypeChangeDisabled =
    regionCode === RegionCode.BE_VLG && supportType === SupportType.ELECTRONIC;

  const showTransitionMessage = regionCode === RegionCode.BE_VLG;

  return (
    <SwFormGrid modStacked>
      <SwColumn>
        <AvailableVouchersTile
          availableVouchers={availableVouchers}
          buyableVouchers={buyableVouchers}
        />
      </SwColumn>

      <fieldset>
        <legend
          id="supportTypeSet"
          className="vl-form__label vl-u-spacer--xsmall"
        >
          {t(i18nKeys.general.label.voucherType)}
        </legend>

        {supportTypeOptionsVisible ? (
          <SwColumn role="radiogroup" aria-labelledby="supportTypeSet">
            <Field
              role="radio"
              name="supportType"
              id={SupportType.ELECTRONIC}
              arial-label={t(i18nKeys.general.supportType.ELECTRONIC)}
              label={t(i18nKeys.general.supportType.ELECTRONIC)}
              component={SwRadioField}
              inputOnChange={(
                e: ChangeEvent<HTMLInputElement>,
                field: FieldProps['field'],
                form: FieldProps['form'],
              ) => handleSupportTypeChange(e, field, form, minQuantity)}
            />
            <Field
              role="radio"
              name="supportType"
              id={SupportType.PAPER}
              arial-label={t(i18nKeys.general.supportType.PAPER)}
              label={t(i18nKeys.general.supportType.PAPER)}
              component={SwRadioField}
              inputOnChange={(
                e: ChangeEvent<HTMLInputElement>,
                field: FieldProps['field'],
                form: FieldProps['form'],
              ) => handleSupportTypeChange(e, field, form, 10)}
              modSpacer
            />

            {showTransitionMessage && (
              <p className="vl-u-text--small" style={{ marginTop: '1rem' }}>
                <Trans
                  i18nKey={
                    i18nKeys.newOrder.stepOrder.form.formatSelector.flanders
                      .description
                  }
                >
                  <SwLink to={getPath(Routes.DASHBOARD)} />
                </Trans>
              </p>
            )}
          </SwColumn>
        ) : (
          <>
            <SwColumn>{t(supportTypeTranslate[supportType])}</SwColumn>
            {!supportTypeChangeDisabled && (
              <SwColumn>
                <SwLink
                  data-testid={
                    dataTest.wallet.order.order.selectAnotherContractType
                  }
                  tagName="button"
                  type="button"
                  onClick={() => setSupportTypeOptionsVisible(true)}
                >
                  {t(i18nKeys.newOrder.stepOrder.cta.selectOtherType)}
                </SwLink>
              </SwColumn>
            )}
          </>
        )}
      </fieldset>

      <Field
        name="quantity"
        type="number"
        label={t(i18nKeys.newOrder.stepOrder.form.voucherAmount.label)}
        column={{ width: '12' }}
        labelColumn={{ width: '12' }}
        component={SwInputTextField}
        modRequired
        min={minQuantity}
        max={maxQuantity}
        parse={(value: string) => (value ? parseInt(value, 10) : value)}
        inputOnChange={handleQuantityChange}
      />

      <OrderTable orderItems={orderItems} />
    </SwFormGrid>
  );
};
