import { RegionCode, SupportType } from '@kaa/api/customers';
import { httpTo } from '@kaa/api/customers/utilities';
import { useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwAlert,
  SwButton,
  SwColumn,
  SwLink,
} from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import { REGION_ELECTRONICVOUCHER_URL } from '../../constants';
import { dataTest } from '../../datatest/keys';
import {
  useApi,
  useSelectedCustomerState,
  useDispatchUpdateCustomer,
  useDispatchUpdateMessages,
} from '../../utils';
import {
  DashboardActionEvent,
  EventLabel,
  sendDashboardActionEventToGTM,
} from '../../utils/google-analytics';
import { getPath, Routes } from '../../routes';

type DashboardAlertSwitchFromPaperProps = {
  supportType: SupportType;
};

export const DashboardAlertSwitchFromPaper = ({
  supportType,
}: DashboardAlertSwitchFromPaperProps) => {
  const { t } = useTranslation();
  const customer = useSelectedCustomerState();
  const dispatchUpdateCustomer = useDispatchUpdateCustomer();
  const dispatchUpdateMessages = useDispatchUpdateMessages();
  const { customers } = useApi();

  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const [{ value: updated, loading }, submit] = useAsyncCallback(async () => {
    if (!customer.emailAddress) {
      window.location.href = getPath(Routes.PREFERENCES);
      return;
    }
    const [error, response] = await httpTo(
      customers.updateCustomerPreferences(customer.id, {
        supportTypePreference: SupportType.ELECTRONIC,
      }),
    );
    if (!response) {
      return;
    }

    const {
      data: { data, messages },
    } = response;

    dispatchUpdateCustomer(data);
    if (messages) {
      dispatchUpdateMessages(messages);
    }
    return !error;
  }, [customers]);

  if (supportType === SupportType.ELECTRONIC) {
    return null;
  }

  if (updated !== undefined) {
    return (
      <SwColumn width="9" widthM="12">
        <SwAlert
          icon={updated ? Icon.CHECK_CIRCLE : Icon.ALERT_CIRCLE}
          modSuccess={updated}
          modError={!updated}
          title={t(
            updated
              ? i18nKeys.alert.switchFromPaper.successTitle
              : i18nKeys.alert.switchFromPaper.errorTitle,
          )}
        />
      </SwColumn>
    );
  }

  const keys = getAlertTranslationKeys(regionCode);

  return (
    <SwColumn width="9" widthM="12">
      <SwAlert
        icon={Icon.INFO_CIRCLE}
        title={t(keys.title)}
        actions={
          <SwButton
            data-testid={dataTest.dashboard.alerts.switchFromPaper}
            modLoading={loading}
            onClick={() => {
              submit();
              sendDashboardActionEventToGTM(
                DashboardActionEvent.ALERT,
                EventLabel.SWITCH_FROM_PAPER,
              );
            }}
            style={{ display: 'block', maxWidth: '100%' }}
          >
            {t(keys.cta)}
          </SwButton>
        }
      >
        {t(keys.body)}{' '}
        <SwLink
          data-testid={dataTest.dashboard.alerts.switchFromPaperInfo}
          to={t(REGION_ELECTRONICVOUCHER_URL[regionCode])}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendDashboardActionEventToGTM(
              DashboardActionEvent.ALERT,
              EventLabel.SWITCH_FROM_PAPER_INFO,
            );
          }}
        >
          {t(keys.moreInfo)}
        </SwLink>
        {keys?.disclaimer && (
          <p style={{ fontSize: '12px', marginTop: '2rem' }}>
            {t(keys.disclaimer)}
          </p>
        )}
      </SwAlert>
    </SwColumn>
  );
};

const getAlertTranslationKeys = (regionCode: RegionCode) => {
  switch (regionCode) {
    case RegionCode.BE_VLG:
      return {
        title: i18nKeys.alert.switchFromPaper.flanders.title,
        body: i18nKeys.alert.switchFromPaper.flanders.body,
        disclaimer: i18nKeys.alert.switchFromPaper.flanders.disclaimer,
        moreInfo: i18nKeys.alert.switchFromPaper.moreInfo,
        cta: i18nKeys.alert.switchFromPaper.cta,
      };
    default:
      return {
        title: i18nKeys.alert.switchFromPaper.title,
        body: i18nKeys.alert.switchFromPaper.body,
        moreInfo: i18nKeys.alert.switchFromPaper.moreInfo,
        cta: i18nKeys.alert.switchFromPaper.cta,
      };
  }
};
