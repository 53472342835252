/**
 * Back For Front (BFF) Customer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * ISO 3166-2:BE \\ BE_BRU - BRUSSELS\\ BE_WAL - WALLONIA\\ BE_VLG - FLANDERS
 */
export type RegionCode = 'BE_BRU' | 'BE_WAL' | 'BE_VLG';

export const RegionCode = {
  BRU: 'BE_BRU' as RegionCode,
  WAL: 'BE_WAL' as RegionCode,
  VLG: 'BE_VLG' as RegionCode,
};
