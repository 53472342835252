import { Customer, RegionCode } from '@kaa/api/customers';
import get from 'lodash.get';
import { useEffect, useMemo } from 'react';
import { navigate } from '@reach/router';
import { getConfig } from '../../../../common/config';
import { hasAccessToAccountPreferences } from '../../../components/functional-header-vl/VlFunctionalHeader.utils';
import { Routes } from '../../../routes';

/**
 * Hook to check if the user has access to the preferences screen
 * If not, redirect to the profile screen
 */
export const usePreferencesAccessGuard = (customer: Customer) => {
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const shouldHaveAccessToPreferences = useMemo(() => {
    // Vlaanderen app has certain conditions to have access to the preferences screen
    if (regionCode === RegionCode.BE_VLG) {
      return hasAccessToAccountPreferences(customer);
    }
    // Other regions have access to the preferences screen all the time
    return true;
  }, [customer]);

  useEffect(() => {
    if (!shouldHaveAccessToPreferences) {
      navigate(Routes.ACCOUNT_PROFILE);
    }
  }, [shouldHaveAccessToPreferences]);

  return shouldHaveAccessToPreferences;
};
