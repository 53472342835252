/* eslint-disable jsx-a11y/anchor-has-content */
import React, { CSSProperties } from 'react';
import { RegionCode } from '@kaa/api/idp';

import { i18nKeys } from '@kaa/i18n/idp/keys';
import { Icon, SwAlert, SwColumn } from '@kaa/ui-flanders/components';
import { Trans, useTranslation } from 'react-i18next';
import { REGION_CONTACT_US_URL } from '../../idp/constants';

interface LoginErrorAlertProps {
  translationKey: string;
  regionCode: RegionCode;
  style?: CSSProperties;
}

export const LoginErrorAlert = ({
  translationKey,
  regionCode,
  style,
}: LoginErrorAlertProps) => {
  const { t } = useTranslation();

  return (
    <SwColumn>
      <SwAlert
        icon={Icon.CLOSE}
        title={t(i18nKeys.error.loginErrorTitle)}
        modError
        style={style}
      >
        <Trans
          i18nKey={translationKey}
          components={[
            <a
              key="link"
              href={t(REGION_CONTACT_US_URL[regionCode])}
              target="_blank"
              rel="noopener noreferrer"
            />,
          ]}
        />
      </SwAlert>
    </SwColumn>
  );
};
