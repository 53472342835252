import { BankAccount, Bundle, VouchersRefundCreate } from '@kaa/api/customers';
import { toCurrencyFormat } from '@kaa/common/utils';
import { max, min, required } from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { TFunction } from 'i18next';
import groupBy from 'lodash.groupby';
import { RefundableQuantityByPrice } from './RefundModal.types';

export const getRefundableQuantityByPrice = (voucherBundles: Bundle[]) =>
  Object.entries(
    groupBy(voucherBundles, (bundle) => bundle.faceValue.toFixed(2)),
  ).reduce<RefundableQuantityByPrice>((acc, [price, bundles]) => {
    const refundableQuantity = bundles.reduce(
      (sum, bundle) => sum + bundle.refundableQuantity,
      0,
    );

    return refundableQuantity
      ? {
          ...acc,
          [price]: {
            refundableQuantity,
            bundles,
          },
        }
      : acc;
  }, {});

export type BankAccountOptions = Array<{ value: BankAccount; text: string }>;

export const getInitialValuesRefundForm = (
  bankAccountOptions: BankAccountOptions,
  groupedByPrice: {},
): VouchersRefundCreate => {
  return {
    requests: Object.keys(groupedByPrice).map((groupPrice) => ({
      faceValue: parseFloat(groupPrice),
      quantity: 0,
    })),
    beneficiaryBankAccount: bankAccountOptions.length
      ? bankAccountOptions[0].value
      : (undefined as any),
  };
};

export const listRefundableVouchersByPrice = (
  refundableQuantityByPrice: {
    [key: string]: { refundableQuantity: number; bundles: Bundle[] };
  },
  t: TFunction,
): string => {
  if (!Object.entries(refundableQuantityByPrice).length) {
    return t(i18nKeys.refund.noVouchersValueCount);
  }
  return ` ${Object.entries(refundableQuantityByPrice)
    .filter(([price, { refundableQuantity }]) => refundableQuantity > 0)
    .map(([price, { refundableQuantity }]) =>
      t(i18nKeys.refund.vouchersValueCount, {
        count: refundableQuantity,
        price: toCurrencyFormat(parseFloat(price)),
      }),
    )
    .join(` and `)
    .replace(/ and (?=.* and )/g, ',')
    .replace(/ and /g, ` ${t(i18nKeys.general.and)} `)} `;
};

export const hasRefundAmount = (
  requests: Array<{ faceValue: number; quantity: number }>,
) => {
  return requests.some(({ quantity }) => quantity > 0);
};

export const defaultRefundValidation = (
  refundableQuantityByPrice: RefundableQuantityByPrice,
) =>
  Object.entries(refundableQuantityByPrice).reduce(
    (acc, [, { refundableQuantity }], index) => ({
      ...acc,
      [`requests.${index}.quantity`]: [
        max(refundableQuantity),
        min(0),
        required,
      ],
    }),
    {},
  );
