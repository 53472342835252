import {
  equalToField,
  existsInError,
  notEqualToPassword,
  password,
  required,
  setError,
} from '@kaa/common/validation';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  SwActionGroup,
  SwButton,
  SwColumn,
  SwForm,
  SwFormColumn,
  SwFormGrid,
  SwFormSubmitMessage,
  SwGrid,
  SwInputPasswordField,
  SwLink,
  SwModalRenderProps,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { Field, Formik, FormikActions } from 'formik';
import get from 'lodash.get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../../../common/config';
import { dataTest } from '../../../../datatest/keys';
import { sendModalInteractionToGTM } from '../../../../utils/google-analytics';
import { createValidatorWithServerErrorHandled } from '../../../../utils/validation';
import { ProfileScreenChangePasswordFieldNames } from '../ProfileScreen.constants';
import { ProfileUserInfoChangePasswordFormState } from '../ProfileScreen.type';

type ConfirmPasswordModalProps = {
  onSubmit: (
    data: ProfileUserInfoChangePasswordFormState,
    FormikActions: FormikActions<ProfileUserInfoChangePasswordFormState>,
  ) => Promise<[Error | undefined, any | undefined]>;
};

export const ProfileUserInfoChangePasswordModal = ({
  onSubmit,
  setConfirmCloseModal,
}: SwModalRenderProps<ConfirmPasswordModalProps>) => {
  const { t, i18n } = useTranslation();
  const tenant = (get(
    getConfig(),
    'buildConfig.oidc.acr_values',
  ) as string)?.split(':')[1];
  const forgotPasswordUrl = `${get(
    getConfig(),
    'app.auth.oidc.authority',
  )}/password-recovery?tenant=${tenant}&localize-key=${get(
    getConfig(),
    'buildConfig.lokaliseKey',
  )}&uiLocale=${i18n.language}`;

  const validate = createValidatorWithServerErrorHandled({
    oldPassword: [required],
    newPassword: [required, password, notEqualToPassword('oldPassword')],
    confirmNewPassword: [
      existsInError(
        `${ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD}.notPasted`,
        {
          id: i18nKeys.errors.form.noPaste,
          defaultMessage: 'You cannot paste',
          displayFocussed: true,
        },
      ),
      required,
      equalToField(ProfileScreenChangePasswordFieldNames.NEW_PASSWORD, {
        defaultMessage: `Must be equal to ${t(i18nKeys.profile.newPassword)}`,
        id: i18nKeys.errors.form.equal,
        displayFocussed: false,
        values: {
          value: t(i18nKeys.profile.newPassword),
        },
      }),
    ],
  });

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validate={validate}
      onSubmit={(...props) => {
        sendModalInteractionToGTM('Change Password Modal', 'submit');
        onSubmit(...props);
      }}
      render={({ handleSubmit, dirty, isSubmitting, setFieldValue }) => {
        setConfirmCloseModal(dirty);
        return (
          <SwGrid>
            <SwColumn width="12">
              <SwTitle tagName="h2">
                {t(i18nKeys.profile.changePasswordModal.title)}
              </SwTitle>
              <p className="vl-u-spacer--xsmall">
                {t(i18nKeys.profile.changePasswordModal.description)}
              </p>
            </SwColumn>
            <SwColumn width="12" widthS="12">
              <SwForm
                onSubmit={handleSubmit}
                data-testid={
                  dataTest.profile.changePasswordModal.formChangePassword
                }
              >
                <SwFormGrid modStacked>
                  <SwFormColumn>
                    <Field
                      name={ProfileScreenChangePasswordFieldNames.OLD_PASSWORD}
                      label={t(i18nKeys.profile.currentPassword)}
                      component={SwInputPasswordField}
                      autoComplete="current-password"
                      data-testid="changePasswordModalOldPasswordInput"
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      modRequired
                    />
                    <SwLink
                      to={forgotPasswordUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid={
                        dataTest.profile.changePasswordModal.linkForgotPassword
                      }
                    >
                      {t(i18nKeys.profile.forgotPassword)}
                    </SwLink>
                  </SwFormColumn>
                  <SwFormColumn>
                    <Field
                      name={ProfileScreenChangePasswordFieldNames.NEW_PASSWORD}
                      label={t(i18nKeys.profile.newPassword)}
                      helpText={t(
                        i18nKeys.profile.changePasswordModal.passwordRules,
                      )}
                      component={SwInputPasswordField}
                      data-testid="changePasswordModalnewPasswordInput"
                      column={{ width: '12' }}
                      labelColumn={{ width: '12' }}
                      modRequired
                    />
                  </SwFormColumn>
                  <Field
                    name={
                      ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD
                    }
                    label={t(i18nKeys.profile.confirmNewPassword)}
                    component={SwInputPasswordField}
                    data-testid="changePasswordModalnewPasswordInputConfirm"
                    onPaste={(e: ClipboardEvent) => {
                      e.preventDefault();
                      setError({
                        setFieldValue,
                        name: `${ProfileScreenChangePasswordFieldNames.CONFIRM_NEW_PASSWORD}.notPasted`,
                      });
                    }}
                    column={{ width: '12' }}
                    labelColumn={{ width: '12' }}
                    modRequired
                  />
                  <SwActionGroup>
                    <SwButton
                      type="submit"
                      modDisabled={!dirty}
                      modLoading={isSubmitting}
                      data-testid={
                        dataTest.profile.changePasswordModal
                          .submitChangePasswordModal
                      }
                    >
                      {t(i18nKeys.general.cta.validate)}
                    </SwButton>
                  </SwActionGroup>
                  <SwFormSubmitMessage />
                </SwFormGrid>
              </SwForm>
            </SwColumn>
          </SwGrid>
        );
      }}
    />
  );
};
