import { RegionCode } from '@kaa/api/customers';
import { OAuthLoginError } from '@kaa/api/idp/model/OAuthLoginError';
import {
  AuthServiceAbstract,
  AuthStorageKeys,
  useAuthState,
} from '@kaa/auth/common';
import { useAsync, useAsyncCallback } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwBadge,
  SwButton,
  SwColumn,
  SwContainer,
  SwFetchErrorMessage,
  SwGrid,
  SwIcon,
  SwInfoTileSpotlight,
  SwLink,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { navigate, RouteComponentProps } from '@reach/router';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../common/config';
import { PageHeader } from '../components';
import {
  LOGIN_CANT_CONNECT_LINKS,
  LOGIN_CONNECTION_PROBLEMS_LINKS,
} from '../constants';
import { getPath, getRouterPath, Routes } from '../routes';
import { AuthContext } from '../types';
import './LoginScreen.style.scss';
import { LoginErrorAlert } from './LoginErrorAlert';

export const LoginScreen = (props: RouteComponentProps) => {
  const [oauthError, setOauthError] = useState<string | null>(null);

  const { t, i18n } = useTranslation();

  const config = getConfig();

  const { getUser } = useAuthState<AuthContext>();

  const { getDirectSigninRequestUrlToIdp, signinSilent } = useAuthState<
    AuthServiceAbstract
  >();

  const regionCode = get(config, 'buildConfig.regionCode') as RegionCode;

  const [
    { value: signinSilentResponse, loading: signinSilentLoading },
    getSigninSilent,
  ] = useAsyncCallback(async () => {
    return signinSilent();
  }, [signinSilent]);

  const generateRandomState = () => {
    return Math.random()
      .toString(36)
      .substring(2);
  };

  const [
    { loading, value: loginUrl, error: loginUrlError },
    getLoginUrl,
  ] = useAsyncCallback(async () => {
    const url = await getDirectSigninRequestUrlToIdp();

    const state = generateRandomState();
    sessionStorage.setItem('oauth_state', state);

    return `${url}&ui_locales=${i18n.language}&state=${state}`;
  }, [getDirectSigninRequestUrlToIdp, i18n.language]);

  const {
    loading: authLoading,
    value: isAuthenticated,
    error: isAuthenticatedError,
  } = useAsync(async () => getUser().then((authenticated) => authenticated), [
    getUser,
    config,
  ]);

  useEffect(() => {
    getLoginUrl();
  }, [getLoginUrl]);

  useEffect(() => {
    getSigninSilent();
  }, [getSigninSilent]);

  useEffect(() => {
    if (isAuthenticated || signinSilentResponse) {
      const redirectionUri =
        sessionStorage.getItem(AuthStorageKeys.CALLBACK_REDIRECT_URI) ||
        getRouterPath(Routes.DASHBOARD);
      sessionStorage.removeItem(AuthStorageKeys.CALLBACK_REDIRECT_URI);

      navigate(redirectionUri);
    }
  }, [isAuthenticated, signinSilentResponse]);

  useEffect(() => {
    const error = localStorage.getItem('oauth_error');

    if (error) {
      setOauthError(error);
      localStorage.removeItem('oauth_error');
    }
  }, []);

  if (
    signinSilentLoading ||
    signinSilentResponse ||
    loading ||
    isAuthenticated ||
    authLoading
  ) {
    return <SwContainer loading />;
  }

  if (isAuthenticatedError || loginUrlError) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage
          onClick={() => {
            window.location.reload();
          }}
        />
      </SwContainer>
    );
  }

  return (
    <SwContainer>
      <SwGrid modStacked>
        <PageHeader title={t(i18nKeys.landing.title)} />
        <SwColumn width="10" widthS="12">
          {oauthError && (
            <LoginErrorAlert
              translationKey={getOAuthErrorTranslationKey(oauthError)}
              regionCode={regionCode}
              style={{ maxWidth: '75%' }}
            />
          )}
          <SwGrid modStacked className="vl-u-flex-v-flex-start">
            <SwColumn width="6" widthS="12">
              <SwInfoTileSpotlight
                tagName="div"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '100%',
                }}
                footer={
                  <SwButton to={loginUrl} modDisabled={!loginUrl}>
                    {t(i18nKeys.landing.cards.signin.cta)}
                  </SwButton>
                }
              >
                <SwBadge icon={Icon.BURGER_PROFILE} modMedium modAccent />
                <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer">
                  {t(i18nKeys.navigation.landing)}
                </SwTitle>
                <p className="vl-u-spacer">
                  {t(i18nKeys.landing.cards.signin.description)}
                </p>
              </SwInfoTileSpotlight>
            </SwColumn>
            <SwColumn width="6" widthS="12">
              <SwInfoTileSpotlight
                tagName="div"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '100%',
                }}
                footer={
                  <SwButton modSecondary to={getPath(Routes.REGISTER)}>
                    {t(i18nKeys.landing.cards.signup.cta)}
                  </SwButton>
                }
              >
                <SwBadge icon={Icon.USER} modMedium modAccent />
                <SwTitle tagName="h3" tagStyle="h4" className="vl-u-spacer">
                  {t(i18nKeys.landing.cards.signup.title)}
                </SwTitle>
                <p className="vl-u-spacer">
                  {t(i18nKeys.landing.cards.signup.description)}
                </p>
              </SwInfoTileSpotlight>
            </SwColumn>
          </SwGrid>
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <SwIcon
            icon={Icon.INFO_CIRCLE}
            className="vl-u-text--action"
            style={{ marginRight: '1rem' }}
          />
          <p className="vl-u-text--small" style={{ display: 'inline-block' }}>
            {t(i18nKeys.login.labels.usefullLinks.title)}
          </p>
          <ul style={{ marginLeft: '3rem', listStyle: 'initial' }}>
            <li>
              <SwLink
                to={t(LOGIN_CONNECTION_PROBLEMS_LINKS[regionCode])}
                target="_blank"
                className="vl-u-text--small"
              >
                {t(i18nKeys.login.labels.usefullLinks.howTo)}
              </SwLink>
            </li>
            <li>
              <SwLink
                to={t(LOGIN_CANT_CONNECT_LINKS[regionCode])}
                target="_blank"
                className="vl-u-text--small"
              >
                {t(i18nKeys.login.labels.usefullLinks.cantConnect)}
              </SwLink>
            </li>
          </ul>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};

const getOAuthErrorTranslationKey = (oauthError: string): string => {
  switch (oauthError) {
    case OAuthLoginError.UserNotBelgian:
      return i18nKeys.error.loginErrorUserNotBelgian;
    case OAuthLoginError.UserNotFound:
      return i18nKeys.error.loginErrorUserNotFound;
    case OAuthLoginError.UserBlocked:
      return i18nKeys.error.loginErrorAccountBlocked;
    default:
      return i18nKeys.error.loginUnknownError;
  }
};
