import { CustomerActionType } from '@kaa/api/customers/model';
import { ActionType, useActionDispatch } from '@kaa/common/context';
import { useActionsHandler } from '@kaa/common/handlers';
import { useAsyncCallback } from '@kaa/common/utils';
import { dynamicDataTest } from '@kaa/common/utils/dataTest';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  selectPage,
  showModalById,
  SwAlert,
  SwColumn,
  SwContainer,
  SwDataTable,
  SwFetchErrorMessage,
  SwGrid,
  SwIcon,
  SwLoader,
  SwPaginator,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerLoadingPageHeader,
  Onboarding,
  PageHeader,
} from '../../../components';
import { Modals, START_BUY_QUERY } from '../../../constants';
import { dataTest } from '../../../datatest/keys';
import { Routes } from '../../../routes';
import { useApi, useSelectedCustomerState } from '../../../utils';
import { ServicesVouchersModals } from '../components/common-modal/ServicesVouchersModals';
import { EventLabelsModalsRoutes } from '../components/common-modal/ServicesVouchersModals.types';
import { ServicesVouchersModalsButtons } from '../components/common-modal/ServicesVouchersModalsButtons';
import { getAvailableVouchers } from '../ServicesVouchers.utils';
import { MyOrderScreenTableRow } from './components/MyOrderScreenTableRow';
import {
  MYORDER_PAGE_SIZE,
  ORDERS,
  OrderTypes,
} from './MyOrderScreen.constants';
import {
  getCustomerVoucherSetQuantityAndTotal,
  orderByDate,
} from './MyOrderScreen.utils';
import { AvailableVouchersTile } from '../components/AvailableVouchersTile';

export const MyOrderScreen = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();
  const dispatchAction = useActionDispatch();

  const customer = useSelectedCustomerState();
  const [order, setOrder] = useState(ORDERS.DESC);
  const [selectedPage, setSelectedPage] = useState(1);

  const canOrder = useActionsHandler(customer.resourceId, [
    CustomerActionType.CAN_ORDER,
  ]);

  const { customers: customersApi } = useApi();

  const [{ value: orderData, loading, error }, getOrderData] = useAsyncCallback(
    async () => {
      const [
        buyableVouchersResponse,
        voucherPurchasesResponse,
      ] = await Promise.all([
        customersApi
          .getBuyableVouchers(customer.id)
          .then(({ data: { data: buyableVouchers, actions } }) => {
            if (actions) {
              dispatchAction({ type: ActionType.ADD, payload: actions });
            }

            return buyableVouchers;
          }),
        customersApi
          .getVoucherOrdersHistory(customer.id)
          .then(({ data: { data } }) => data),
      ]);

      return { ...buyableVouchersResponse, ...voucherPurchasesResponse };
    },
    [customersApi],
    { loading: true },
  );

  useEffect(() => {
    getOrderData();
  }, [getOrderData]);

  useEffect(() => {
    if (location?.search?.includes(START_BUY_QUERY)) {
      showModalById(Modals.ORDER_MODAL_ID);
    }
    // /!\ TODO Don't forget to add any response from new HTTP request
  }, [location, orderData]);

  if (!orderData && loading) {
    return (
      <ContainerLoadingPageHeader
        title={t(i18nKeys.navigation.orders)}
        introduction={t(i18nKeys.orders.introduction)}
      />
    );
  }

  if (error || !orderData) {
    return (
      <SwContainer error>
        <SwFetchErrorMessage onClick={getOrderData} />
      </SwContainer>
    );
  }

  const { buyableVouchers, purchaseHistory, pendingOrder } = orderData;

  const availableVouchers = getAvailableVouchers(buyableVouchers);

  const orders = [
    ...(pendingOrder?.voucherSets
      ? [
          getCustomerVoucherSetQuantityAndTotal({
            ...pendingOrder,
            type: OrderTypes.PENDING,
          }),
        ]
      : []),
    ...(purchaseHistory
      ? orderByDate(
          purchaseHistory
            .filter((purchase) => purchase?.voucherSets)
            .map((purchase) =>
              getCustomerVoucherSetQuantityAndTotal({
                ...purchase,
                type: OrderTypes.PURCHASE,
              }),
            ),
          'paymentDate',
          order,
        )
      : []),
  ];

  return (
    <SwContainer>
      <Onboarding activeRoute={Routes.SERVICES_VOUCHERS_ORDER} />
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <PageHeader
            title={t(i18nKeys.navigation.orders)}
            introduction={t(i18nKeys.orders.introduction)}
            className="push-pageHeader"
          />
          <AvailableVouchersTile
            availableVouchers={availableVouchers}
            buyableVouchers={buyableVouchers}
          />
        </SwColumn>
        <ServicesVouchersModalsButtons
          customer={customer}
          route={Routes.SERVICES_VOUCHERS_ORDER as EventLabelsModalsRoutes}
        />
      </SwGrid>
      <SwGrid>
        {canOrder && (
          <SwColumn width="10" widthS="12">
            <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
              {t(i18nKeys.orders.subtitle)}
            </SwTitle>
          </SwColumn>
        )}
        {!!orders.length && (
          <SwColumn width="10" widthS="12" className="vl-u-spacer--small">
            <div className="vl-u-table-overflow">
              <SwDataTable modCollapsedM>
                <thead data-testid={dataTest.myorders.tableHead}>
                  <tr>
                    <th>{t(i18nKeys.orders.table.vouchers)}</th>
                    <th>{t(i18nKeys.general.label.product)}</th>
                    <th>
                      <button
                        data-testid={dataTest.myorders.tableSort}
                        type="button"
                        aria-label="Asc or Desc"
                        style={{
                          border: 'none',
                          padding: '0',
                          fontSize: 'inherit',
                          fontWeight: 'inherit',
                          overflow: 'hidden',
                          position: 'relative',
                          paddingRight: '2rem',
                        }}
                        onClick={() =>
                          setOrder(
                            order === ORDERS.DESC ? ORDERS.ASC : ORDERS.DESC,
                          )
                        }
                      >
                        {t(i18nKeys.general.label.paymentDate)}
                        <SwIcon
                          style={{
                            position: 'absolute',
                            top: '0.2rem',
                            right: '0',
                          }}
                          icon={Icon.DATA_TRANSFER}
                        />
                      </button>
                    </th>
                    <th>{t(i18nKeys.general.label.amount)}</th>
                    <th>{t(i18nKeys.orders.table.status)}</th>
                    <th />
                  </tr>
                </thead>
                <tbody data-testid={dataTest.myorders.tableBody}>
                  {loading && (
                    <tr>
                      <td
                        colSpan={6}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <SwLoader
                          style={{
                            display: 'inline-block',
                          }}
                          modMessageHidden
                        />
                      </td>
                    </tr>
                  )}
                  {selectPage(orders, selectedPage, MYORDER_PAGE_SIZE).map(
                    (order, i) => (
                      <MyOrderScreenTableRow
                        key={`${order.id}-${i}`}
                        order={order}
                        testId={dynamicDataTest(dataTest.myorders.tableRows, {
                          rowI: order.id,
                        })}
                      />
                    ),
                  )}
                </tbody>
              </SwDataTable>
            </div>
          </SwColumn>
        )}
        {canOrder && !orders.length && (
          <SwColumn width="9" widthS="12">
            <SwAlert
              icon={Icon.INFO_CIRCLE}
              content={t(i18nKeys.orders.alert.noOrderContent)}
              title={t(i18nKeys.orders.alert.noOrderTitle)}
            />
          </SwColumn>
        )}
        <SwColumn width="10" widthS="12">
          <SwPaginator
            itemCount={orders.length}
            pageSize={MYORDER_PAGE_SIZE}
            selectedPage={selectedPage}
            setPage={setSelectedPage}
          />
        </SwColumn>
      </SwGrid>
      <ServicesVouchersModals
        customer={customer}
        route={Routes.SERVICES_VOUCHERS_ORDER as EventLabelsModalsRoutes}
        onSuccess={getOrderData}
      />
    </SwContainer>
  );
};
