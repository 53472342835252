import { Bundle, VouchersRefundItemCreate } from '@kaa/api/customers';

export type RefundSummaryType = {
  bundles: Bundle[];
  requests: VouchersRefundItemCreate[];
};

type Total = {
  refundableUnitPrice: number;
  faceValue: number;
  quantity: number;
  sum: number;
};

type RefundElectronicSummary = {
  total: number;
  totalsPerRefundPrice: Total[];
};

export const calculateRefundSummary = ({
  bundles,
  requests,
}: RefundSummaryType): RefundElectronicSummary => {
  const remainingNbrOfVoucherToRefund: {
    [price: string]: number;
  } = requests.reduce(
    (remaining, { faceValue, quantity }) => ({
      ...remaining,
      [faceValue.toFixed(2)]: quantity,
    }),
    {},
  );
  const initialSummedRefund: RefundElectronicSummary = {
    total: 0,
    totalsPerRefundPrice: getInitialTotalsPerRefundPrice(bundles),
  };
  const summary = [...bundles]
    .sort((a, b) => a.expirationDate.localeCompare(b.expirationDate))
    .filter((bundle) => bundle.refundableQuantity > 0)
    .reduce((summedRefund, bundle) => {
      const amountToRefundAtCurrentBundlePrice =
        remainingNbrOfVoucherToRefund[bundle.faceValue.toFixed(2)];
      if (!amountToRefundAtCurrentBundlePrice || !bundle.refundableQuantity) {
        return summedRefund;
      }
      const { total, totalsPerRefundPrice } = summedRefund;

      const refundCountFromCurrentBundle = Math.min(
        bundle.refundableQuantity,
        amountToRefundAtCurrentBundlePrice,
      );
      remainingNbrOfVoucherToRefund[
        bundle.faceValue.toFixed(2)
      ] -= refundCountFromCurrentBundle;
      const refundSumFromCurrentBundle =
        refundCountFromCurrentBundle * bundle.refundableUnitPrice;

      const currentGroupIndex = totalsPerRefundPrice.findIndex(
        ({ refundableUnitPrice, faceValue }) =>
          bundle.refundableUnitPrice === refundableUnitPrice &&
          bundle.faceValue === faceValue,
      );
      const currentGroup = totalsPerRefundPrice[currentGroupIndex];
      if (currentGroup) {
        return {
          total: total + refundSumFromCurrentBundle,
          totalsPerRefundPrice: totalsPerRefundPrice.map((value, index) =>
            index === currentGroupIndex
              ? {
                  ...currentGroup,
                  quantity:
                    currentGroup.quantity + refundCountFromCurrentBundle,
                  sum: currentGroup.sum + refundSumFromCurrentBundle,
                }
              : value,
          ),
        };
      }
      return {
        total: summedRefund.total + refundSumFromCurrentBundle,
        totalsPerRefundPrice: [
          ...totalsPerRefundPrice,
          {
            refundableUnitPrice: bundle.refundableUnitPrice,
            faceValue: bundle.faceValue,
            quantity: refundCountFromCurrentBundle,
            sum: refundSumFromCurrentBundle,
          },
        ],
      };
    }, initialSummedRefund);
  return {
    ...summary,
    totalsPerRefundPrice: summary.totalsPerRefundPrice.sort((a, b) => {
      return a.faceValue === b.faceValue
        ? a.refundableUnitPrice - b.refundableUnitPrice
        : a.faceValue - b.faceValue;
    }),
  };
};

function getInitialTotalsPerRefundPrice(bundles: Bundle[]): Total[] {
  return [...bundles].reduce<Total[]>(
    (totals, { faceValue, refundableUnitPrice }) => {
      if (
        !totals.find(
          (total) =>
            total.faceValue === faceValue &&
            total.refundableUnitPrice === refundableUnitPrice,
        )
      ) {
        return [
          ...totals,
          {
            refundableUnitPrice,
            faceValue,
            quantity: 0,
            sum: 0,
          },
        ];
      }
      return totals;
    },
    [],
  );
}
