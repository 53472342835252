import React, { useMemo } from 'react';
import { i18nKeys } from '@kaa/i18n/customers/keys';

import { CustomerVoucherSet } from '@kaa/api/customers';
import { Icon, SwBadge, SwInfoTile } from '@kaa/ui-flanders/components';
import { toCurrencyFormat } from '@kaa/common/utils';
import { useTranslation } from 'react-i18next';
import { dataTest } from '../../../datatest/keys';

interface Props {
  availableVouchers: number;
  buyableVouchers: CustomerVoucherSet[];
}

export const AvailableVouchersTile = ({
  availableVouchers,
  buyableVouchers,
}: Props) => {
  const { t } = useTranslation();

  const subtitle = useMemo(
    () =>
      buyableVouchers.map((voucher, i) => (
        <span key={`subtitle-voucher-${i}`} className="vl-u-display-block">
          {t(i18nKeys.orders.alert.subTitle, {
            quantity: voucher.quantity,
            count: voucher.quantity,
            faceValue: toCurrencyFormat(voucher.faceValue),
          })}
        </span>
      )),
    [buyableVouchers],
  );

  return (
    <SwInfoTile
      data-testid={dataTest.myorders.alertAvailableVouchers}
      tagName="div"
      badge={<SwBadge modLarge modAccent icon={Icon.VOUCHER_DOWNLOAD} />}
      title={t(i18nKeys.orders.alert.availableVouchers, {
        count: availableVouchers,
        availableVouchers,
      })}
      subtitle={subtitle}
    />
  );
};
