import { RegionCode, VouchersOrderConfirmation } from '@kaa/api/customers';
import { Format, toCurrencyFormat } from '@kaa/common/utils';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import {
  Icon,
  SwActionGroup,
  SwAlert,
  SwButton,
  SwColumn,
  SwFormMessageLabel,
  SwGrid,
  SwTitle,
} from '@kaa/ui-flanders/components';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import get from 'lodash.get';
import { getConfig } from '../../../../../../common/config';

type OrderStepConfirmationProps = {
  vouchersOrder: VouchersOrderConfirmation;
  backTo: () => void;
};

export const OrderStepConfirmation = ({
  vouchersOrder,
  backTo = () => undefined,
}: OrderStepConfirmationProps) => {
  const { t } = useTranslation();
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;

  const canPayWithQrCode = [RegionCode.BE_WAL, RegionCode.BE_BRU].includes(
    regionCode,
  );

  return (
    <>
      <SwColumn className="vl-u-spacer">
        <SwTitle tagName="h2" className="vl-u-spacer--xsmall">
          {t(i18nKeys.newOrder.stepConfirmation.title)}
        </SwTitle>
        <p className="vl-u-spacer">
          {t(i18nKeys.newOrder.stepConfirmation.description, {
            amount: vouchersOrder.requestedQuantity,
          })}
        </p>
        <SwTitle tagName="h3" tagStyle="h5" className="vl-u-spacer--xsmall">
          {t(i18nKeys.newOrder.stepConfirmation.paymentInfo)}
        </SwTitle>
        <SwGrid className="vl-u-spacer">
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.newOrder.stepConfirmation.amountToPay)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
            {toCurrencyFormat(vouchersOrder.totalPrice)}
          </SwColumn>
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.general.label.beneficiaryIban)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
            {vouchersOrder.beneficiaryBankAccount}
          </SwColumn>
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.newOrder.stepConfirmation.accountOwner.title)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
            {vouchersOrder.beneficiaryName}{' '}
            {vouchersOrder.beneficiaryStreetAndHouseNumber}{' '}
            {vouchersOrder.beneficiaryPostcodeAndCity}
          </SwColumn>
          <SwColumn width="4" widthM="12" className="vl-u-text--bold">
            <SwFormMessageLabel>
              {t(i18nKeys.newOrder.stepConfirmation.communication)}
            </SwFormMessageLabel>
          </SwColumn>
          <SwColumn width="8" widthM="12" className="vl-u-spacer--xsmall">
            {vouchersOrder.structuredMessage}
          </SwColumn>
        </SwGrid>
      </SwColumn>
      <SwColumn className="vl-u-spacer">
        <SwAlert
          icon={Icon.ALERT_CIRCLE}
          title={t(i18nKeys.newOrder.stepConfirmation.alert.title, {
            expiredDate: Format.date(
              String(DateTime.fromISO(vouchersOrder.paymentDueDate)),
            ),
          })}
          content={t(i18nKeys.newOrder.stepConfirmation.alert.body, {
            paymentDelay: vouchersOrder.paymentDelay,
          })}
        />
      </SwColumn>
      {canPayWithQrCode && (
        <>
          <SwColumn className="vl-u-spacer vl-u-align-center">
            <p style={{ fontWeight: 500, fontSize: '1.8rem' }}>
              {t(i18nKeys.general.or).toUpperCase()}
            </p>
          </SwColumn>
          <SwColumn className="vl-u-spacer">
            <SwAlert
              icon={Icon.ALERT_CIRCLE}
              title={t(i18nKeys.newOrder.stepConfirmation.qrCodeAlert.title)}
              content={t(i18nKeys.newOrder.stepConfirmation.qrCodeAlert.body)}
            />
          </SwColumn>
          <SwColumn className="vl-u-spacer vl-u-align-center">
            <img
              alt="qrCode"
              src={vouchersOrder.qrCode}
              height={150}
              width={150}
            />
          </SwColumn>
        </>
      )}

      <SwActionGroup>
        <SwButton modBlock modLarge onClick={backTo}>
          {t(i18nKeys.newOrder.stepConfirmation.cta.back)}
        </SwButton>
      </SwActionGroup>
    </>
  );
};
