import { POSTCODES_CITIES } from '@kaa/api/common/mocks';
import { ValueOf } from '@kaa/common/types';
import faker from 'faker';
import { DateTime } from 'luxon';
import {
  Address,
  ContractType,
  Customer,
  CustomerActionType,
  CustomerCreate,
  CustomerPreferences,
  CustomerPreferencesResponse,
  CustomerResponse,
  CustomerResponseWithMessages,
  CustomerSupportMessageCreate,
  CustomerSupportMessageCreatedResponse,
  CustomerUpdate,
  MessageCode,
  MessageType,
  SupportType,
  TitleCode,
} from '../model';
import { getLanguage, getLanguages } from './language';

type CustomerActions = { [key in ValueOf<typeof CustomerActionType>]: boolean };

export const getCustomerContracts = () => {
  return [
    {
      authorizationId: faker.random.number(999),
      type: ContractType.STANDARD,
    },
  ].concat(
    faker.random.boolean()
      ? [
          {
            authorizationId: faker.random.number(999),
            type: ContractType.MATERNITY,
          },
        ]
      : [],
  );
};
export const getCustomerAddress = (address?: Partial<Address>) => {
  const POSTCODE_CITY = faker.helpers.randomize(POSTCODES_CITIES);
  return {
    streetId: faker.random.number(999),
    houseNumber: faker.random.number(999).toString(),
    boxNumber: faker.random.number(9).toString(),
    street: faker.address.streetName(),
    postcode: POSTCODE_CITY.postcode,
    city: faker.helpers.randomize(POSTCODE_CITY.names),
    country: 'Belgium',
    ...address,
  };
};
export const getCustomer = (customer: Partial<Customer> = {}): Customer => {
  const firstName = faker.name.firstName(14);
  const lastName = faker.name.lastName();

  return {
    id: faker.random.number(999),
    firstName,
    lastName,
    niss: faker.random.number(99999999999).toString(),
    title: faker.helpers.randomize(Object.values(TitleCode)),
    language: getLanguage(),
    emailAddress: faker.random.boolean()
      ? faker.internet.email(firstName, lastName)
      : undefined,
    phoneNumber: faker.phone.phoneNumber('0## ## ## ##'),
    mobilePhoneNumber: faker.phone.phoneNumber('04## ## ## ##'),
    refundBankAccount: { iban: 'BE71199234524669' },
    mainAddress: getCustomerAddress(),
    useMainAddressForPaperVoucherDelivery: faker.random.boolean(),
    paperVoucherDeliveryAddress: getCustomerAddress(),
    preferredSupportType: faker.helpers.randomize(
      Object.values(SupportType),
    ) as SupportType,
    // should be formatted as 1973-01-11T00:00:00Z
    birthDate: DateTime.fromJSDate(faker.date.past(18))
      .startOf('day')
      .toISO(),
    resourceId: faker.random.uuid(),
    contracts: getCustomerContracts(),
    availableLanguages: getLanguages(),
    ...customer,
  };
};
export const getCustomerCreate = (
  customer: Partial<Customer> = {},
): CustomerCreate => {
  const customerCreate = getCustomer(customer);

  return ({
    ...customerCreate,
    refundBankAccount: 'BE71199234524669',
    preferredSupportType: SupportType.ELECTRONIC,
  } as any) as CustomerCreate;
};

export const getCustomerResponse = ({
  data,
  actions,
}: {
  data?: Partial<Customer>;
  actions?: CustomerActions;
} = {}): CustomerResponse => {
  const { resourceId = faker.random.uuid() } = data || {};
  return {
    actions: {
      [resourceId]: Object.values(CustomerActionType).reduce<CustomerActions>(
        (acc, action) => ({ ...acc, [action]: faker.random.boolean() }),
        (actions || ({} as any)) as CustomerActions,
      ),
    },
    data: getCustomer(data),
  };
};

const getCustomerPreferences = (): CustomerPreferences => {
  return {
    supportTypePreference: faker.helpers.randomize(
      Object.keys(SupportType),
    ) as SupportType,
  };
};

export const getCustomerPreferencesResponse = (): CustomerPreferencesResponse => {
  return {
    data: getCustomerPreferences(),
  };
};

export const supportMessageResponse = (
  customerSupportMessageCreate: CustomerSupportMessageCreate,
): CustomerSupportMessageCreatedResponse => {
  return {
    data: {
      customerEmailAddress: faker.internet.email(),
    },
  };
};

export const updateCustomerResponse = (
  customerUpdate: CustomerUpdate,
): CustomerResponseWithMessages => {
  const resourceId = faker.random.uuid();
  return {
    ...getCustomerResponse({ data: { resourceId, ...customerUpdate } }),
    messages: {
      [resourceId]: [...Array(faker.random.number({ min: 0, max: 1 }))].map(
        () => ({
          type: faker.helpers.randomize(Object.values(MessageType)),
          code: faker.helpers.randomize(Object.values(MessageCode)),
        }),
      ),
    },
  };
};
